import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';

import AllStudents from './pages/Desk/Student/AllStudents';
import Student from './pages/Desk/Student/Student';

import FilterGenders from './pages/Admin/Filter/Gender/Genders';

function App() {
  return (
    <Routes>
      <Route exact path = "/" element = { <Login /> }></Route>
      <Route exact path = "/all-students" element = { <AllStudents /> }></Route>
      <Route exact path = "/student/:id" element = { <Student /> }></Route>

      <Route exact path = "/filter-by-gender/display-all" element = { <FilterGenders /> }></Route>
    </Routes>
  );
}

export default App;
