export function getLocalTime(
  date_time,
  locale = 'en-IN',
  options = {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    timeZone: "Asia/Kolkata",
  }
) {
  return new Date(date_time).toLocaleString(locale, options)
}
