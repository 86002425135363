
export const genderColumnDefs = [
  {
    field: "gender_name",
    headerName: "Gender Name"
  },
  {
    field: "present_count",
    headerName: "Present Count"
  },
  {
    field: "absent_count",
    headerName: "Absent Count"
  },
  {
    field: "not_fill_count",
    headerName: "Not Fill Count"
  },
  {
    field: "total_count",
    headerName: "Total Count"
  },
]
