import Navbar from "../../../Components/Nav";
import { IsLoggedIn, jwtHeader } from "../../../utils/AuthManager";
import {
  Text,
} from '@chakra-ui/react';

import { useCallback, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS

import axios from "axios";

import { studentsColumnDefs } from "./StudentColumns.js";
import { defaultColDefinition } from "../../../utils/data/DefaultColumn";

import ChangePageTitle from "../../../Components/ChangePageTitle";

export default function AllRegister() {
  const gridRef = useRef();

  const [columnDefs] = useState(studentsColumnDefs);
  const [rowData, setRowData] = useState([]);

  const containerStyle = { width: '100%', height: '100%' };
  const gridStyle = { height: 600, width: '100%' };

  const onGridReady = useCallback((params) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/student/all`,
      headers: jwtHeader,
    }).then((response) => {
      setRowData(response.data);
    });
  }, []);

  return (
    <>
      <IsLoggedIn />
      <ChangePageTitle pageTitle="Freshers Day Desk Panel | All Registrations"></ChangePageTitle>
      <Navbar>
        <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='5'>All Students</Text>

        <div style={containerStyle}>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDefinition}
              suppressExcelExport={true}
              onGridReady={onGridReady}
            ></AgGridReact>
          </div>
        </div>
      </Navbar>
    </>
  );
}
