
import React, { useEffect, useState } from 'react';

import axios from "axios";

import {
  Box,
  Flex,
  HStack,
  VStack,

  IconButton,
  CloseButton,

  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,

  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import {
  FiMenu,
  FiChevronDown,
  FiBook,
  FiUsers
} from 'react-icons/fi';
import { Logout, jwtHeader } from '../utils/AuthManager';

const LinkItems1 = [
  { name: 'All Students', href: '/all-students', icon: FiBook }
];

const LinkItems2 = [
  { name: 'Student-Gender', href: '/filter-by-gender/display-all', icon: FiUsers }
];


export default function SidebarWithHeader({
  children,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflowY={'scroll'}
      {...rest}>
      <Flex my="6" textAlign={'center'} mx="2" justifyContent="space-between">
        <Text fontSize="xl" fontFamily="monospace" fontWeight="bold">
          Freshers Day Desk
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Text ml={'3'} color={'#3960E9'} fontWeight={'black'}>List</Text>
      {LinkItems1.map((link) => (
        <NavItem key={link.name} icon={link.icon} href={link.href}>
          {link.name}
        </NavItem>
      ))}
      <Text ml={'3'} color={'#3960E9'} fontWeight={'black'}>Count</Text>
      {LinkItems2.map((link) => (
        <NavItem key={link.name} icon={link.icon} href={link.href}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};


const NavItem = ({ icon, href, children, ...rest }) => {
  return (
    <Link href={href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="2"
        my="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={window.location.pathname.includes(href) ? '#3960E9' : '#FFF'}
        color={window.location.pathname.includes(href) ? '#FFF' : '#121212'}
        _hover={{
          bg: '#3960E9',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};


const MobileNav = ({ onOpen, ...rest }) => {
  const [name, setName] = useState();
  const [role, setRole] = useState();
  useEffect(() => {

    // Get Current User Details
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/user/me`,
      headers: jwtHeader,
    }).then((response) => {
      setName(response.data.full_name);
      setRole(response.data.role.role_name)
    });

  }, []);
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        Freshers Day Desk
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{name}</Text>
                  <Text fontSize="xs" color="gray.600">{role}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={() => { Logout() }}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
