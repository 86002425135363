import Navbar from "../../../Components/Nav";
import { IsLoggedIn, jwtHeader } from "../../../utils/AuthManager";
import {
  Text,
  SimpleGrid,
  Input,
  Select,
  Button,
} from '@chakra-ui/react';

import { createRef, useEffect, useState } from "react";
import axios from "axios";
import { getLocalTime } from "../../../utils/DateStuff";
import ChangePageTitle from "../../../Components/ChangePageTitle";

export default function Student() {

  const [register, setRegister] = useState({});
  const [listOfStatuses, setlistOfStatuses] = useState([]);
  const [parentListOfStatuses, setParentListOfStatuses] = useState([]);


  useEffect(() => {

    // Getting list of statuses
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/status-code/all`
    }).then(
      (response) => { setlistOfStatuses(response.data); }
    );

    // Getting list of statuses
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/parent-status-code/all`
    }).then(
      (response) => { setParentListOfStatuses(response.data); }
    );

    // Getting registration info
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/student/info/${window.location.pathname.slice(9)}`,
      headers: jwtHeader,
    }).then(
      (response) => {
        setRegister(response.data);
      }).catch(
        (err) => {
          console.log(err);
        }
      );
  }, []);

  const commentSection = createRef();
  const statusCode = createRef();
  const parentStatusCode = createRef();

  function updateData(e) {
    e.preventDefault();
    console.log(commentSection.current.value);
    console.log(statusCode.current.value);
    console.log(window.location.pathname.slice(9))
    axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/desk/student/update/status/${window.location.pathname.slice(9)}`,
      headers: jwtHeader,
      data: {
        "comment": commentSection.current.value,
        "status_id": statusCode.current.value,
        "parent_status_id": parentStatusCode.current.value
      },
    }).then((response) => {
      console.log(response.data);
      alert("Updated");
    }).catch(
      (err) => {
        alert(err);
      }
    );
  }


  return (
    <>
      <IsLoggedIn />
      <ChangePageTitle pageTitle={"Freshers Day Desk Panel | Student Info " + window.location.pathname.slice(9)}></ChangePageTitle>
      <Navbar>
        <Text fontSize={'3xl'} fontWeight={'bold'} color={'#323232'} my='5'>ID: {window.location.pathname.slice(9)}</Text>
        <form>
          {register &&
            <>
              <SimpleGrid columns={{ base: 1, sm: 3, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Name</Text>
                  <Text fontSize={'xl'} my={2}>{register.name}</Text>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Department</Text>
                  <Text fontSize={'xl'} my={2}>{register.department}</Text>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>College</Text>
                  <Text fontSize={'xl'} my={2}>{register.college?.college_name}</Text>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Gender</Text>
                  <Text fontSize={'xl'} my={2}>{register.gender?.gender_name}</Text>
                </div>

                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Stream</Text>
                  <Text fontSize={'xl'} my={2}>{register.stream?.stream_name}</Text>
                </div>

                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Last Modified at</Text>
                  <Text fontSize={'xl'} my={2}>{getLocalTime(register.updated_at)}</Text>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Last Modified by</Text>
                  <Text fontSize={'xl'} my={2}>{register.updater?.full_name}</Text>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Status</Text>
                  <Select ref={statusCode} bg={'#FFF'} key={register?.status} defaultValue={register?.status?.status_id}>
                    <option value="" disabled>Select Option</option>
                    {
                      listOfStatuses.map(
                        (status) => <option value={status?.status_id} key={status?.status_id}>{status?.status_name}</option>
                      )
                    }
                  </Select>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Parents Came To Event</Text>
                  <Select ref={parentStatusCode} bg={'#FFF'} key={register?.parent_status} defaultValue={register?.parent_status?.status_id}>
                    <option value="" disabled>Select Option</option>
                    {
                      parentListOfStatuses.map(
                        (parent_status) => <option value={parent_status?.status_id} key={parent_status?.status_id}>{parent_status?.status_name}</option>
                      )
                    }
                  </Select>
                </div>
                <div>
                  <Text fontSize={'xl'} fontWeight={'bold'}>Comments</Text>
                  <Input colorScheme={'blue'} bg={'#FFF'} ref={commentSection} fontSize={'xl'} my={2} defaultValue={register?.comment}></Input>
                </div>

              </SimpleGrid>
              <Button
                onClick={updateData}
                type={"submit"}
                bg={'blue.400'}
                color={'white'}
                my={4}
                _hover={{
                  bg: 'blue.500',
                }}>
                Update
              </Button>
            </>
          }
        </form>
      </Navbar>
    </>
  );
}
