import { useEffect } from "react";
import { deleteCookie, getCookie } from "./CookieManager";

export function IsLoggedIn() {
  useEffect(
    () => {
      const jwt = getCookie('desk_reg_jwt');
      if (jwt === null) {
        window.location = "/";
      }
    },
    []
  );
  return (<></>);
}

export function Logout() {
  deleteCookie('desk_reg_jwt');
  window.location = "/";
}

export const jwtHeader = {
  "accept": "application/json",
  "Authorization": `Bearer ${getCookie('desk_reg_jwt')}`
}
